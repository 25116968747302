import React from "react";
import { Redirect, Route, Switch, BrowserRouter  } from "react-router-dom";

// routes config
import routes from "./routes";

import "./App.css";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

function App() {
  return (
    <main className="c-main">
      <BrowserRouter >
        <React.Suspense fallback={loading}>
          <Switch>
          {routes.map((route, idx) => {
              return route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                      <route.component {...props} />
                  
                  )} />
              )
            })}
            <Redirect from="/" to="/home" />
          </Switch>
        </React.Suspense>
      </BrowserRouter >
    </main>
  );
}

export default App;

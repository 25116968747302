import React from "react";

const Home = React.lazy(() => import("./pages/home"));
const ContactMe = React.lazy(() => import("./pages/contactme"));
const MyWork = React.lazy(() => import("./pages/mywork"));
const Tabliya = React.lazy(() => import("./pages/tabliya"));
const ELearning = React.lazy(() => import("./pages/elearning"));
const Moves = React.lazy(() => import("./pages/moves"));
const Makers = React.lazy(() => import("./pages/makers"));
const Tari2ak = React.lazy(() => import("./pages/tari2ak"));

let routes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/home", exact: true, name: "Home", component: Home },
  { path: "/contact", exact: true, name: "ContactMe", component: ContactMe },
  { path: "/work", exact: true, name: "MyWork", component: MyWork },
  { path: "/tabliya", exact: true, name: "Tabliya", component: Tabliya },
  { path: "/elearning", exact: true, name: "ELearning", component: ELearning },
  { path: "/moves", exact: true, name: "Moves", component: Moves },
  { path: "/makers", exact: true, name: "Makers", component: Makers },
  { path: "/tari2na", exact: true, name: "3atari2na", component: Tari2ak },
];

export default routes;
